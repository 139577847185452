export const TEXT_TYPE = 'text';
export const PICTURE_TYPE = 'picture';
export const VIDEO_TYPE = 'video';
export const CARD_TYPE = 'card';
export const MESSAGE_TYPES = [TEXT_TYPE, PICTURE_TYPE, VIDEO_TYPE, CARD_TYPE];

export type MessageType = typeof MESSAGE_TYPES[number];

export type Preferences = {
  accentColor: string;
  complementaryColor: string;
  botMessageColor: string;
  botMessageBackgroundColor: string;
  backgroundColor: string;
  headerLogo: string;
  headerTitle: string;
  botPicture: string;
  userPicture: string;
  onboardingMessage: string;
  userInputPlaceholder: string;
  expanderLogo: string;
  expanderTitle: string;
  conversationTimeToLive: number;
  openingType: string;
  welcomeMessage: string;
}

export type Participant = {
  _id: string;
  updatedAt: string;
  createdAt: string;
  conversation: string;
  senderId: string;
  type: string;
  __v: number;
  isBot: boolean;
}

export type Attachment = {
  type: MessageType;
  content: any;
}

export type Message = {
  id: string;
  conversation: string;
  attachment: Attachment;
  participant: Participant,
}

export type Conversation = {
  id: string;
  connector: string;
  chatId: string;
  channel: string;
  fallbacked: boolean;
  participants: Participant[];
  messages: Message[];
}
