import { Message, TEXT_TYPE, MessageType } from './types';

const mediaRegex = /^\[(video):(vimeo|youtube):([^\]]+)]$/;

const src:{[provider:string]:(id:string) => string} = {
  vimeo: id => `https://player.vimeo.com/video/${id}`,
  youtube: id => `https://www.youtube.com/embed/${id}`,
};

export function parseType(message:Message):Message {
  const content = message.attachment.content && message.attachment.content.trim
    ? message.attachment.content.trim()
    : message.attachment.content;

  if (!message.participant.isBot || message.attachment.type !== TEXT_TYPE || !mediaRegex.test(content)) {
    return message;
  }

  const match = mediaRegex.exec(content);

  if (!match) {
    return message;
  }

  const type = match[1] as MessageType;
  const url = src[match[2]];

  if (!url) {
    return message;
  }

  const id = match[3];
  const attachment = { type, content: url(id) };

  return { ...message, attachment };
}
