import marked from 'marked';
import { TEXT_TYPE, PICTURE_TYPE, VIDEO_TYPE, CARD_TYPE } from '../api/types';
import { Message } from '../flow/types';
import { fullHeight } from '../utils';
import style from './style.css';

fullHeight();

const isFullMode = document.currentScript.dataset.mode === 'full';

const chat = document.createElement('div');
chat.className = isFullMode ? style.chat : style.closedChat;
document.currentScript.parentElement.insertBefore(chat, document.currentScript);

const copyright = document.createElement('div');
copyright.className = style.copyright;
copyright.innerHTML = 'Propiedad de <a href="https://www.accuesp.com/" target="_blank" rel="noopener noreferrer">Accu España</a>, con el patrocinio de <a href="https://www.takeda.com/es-es/" target="_blank" rel="noopener noreferrer">Takeda</a>.';
chat.appendChild(copyright);

const header = document.createElement('div');
header.className = style.header;
chat.appendChild(header);

const title = document.createElement('div');
title.className = style.title;
title.innerText = 'Iria IA';
header.appendChild(title);

export const messages = document.createElement('ul');
messages.className = style.messages;
chat.appendChild(messages);

export const sender = document.createElement('form');
sender.className = style.sender;
chat.appendChild(sender);

export const input = document.createElement('input');
input.type = 'text';
sender.appendChild(input);

export const button = document.createElement('button');
button.type = 'submit';
sender.appendChild(button);

const renderer = new marked.Renderer();
renderer.link = (href:string, title:string, text:string) => `<a target="_blank" rel="noopener" href="${href}" title="${title}">${text}</a>`;

function scrollToBottom() {
  messages.scrollTop = messages.scrollHeight - messages.clientHeight;
}

function getContent(message:Message) {
  const content = document.createElement('div');

  switch (message.type) {
    case TEXT_TYPE:
      content.innerHTML = marked(message.content, { renderer });
      return content;

    case PICTURE_TYPE: {
      const picture = document.createElement('img');
      picture.className = style.picture;
      picture.src = message.content;
      content.appendChild(picture);
      return content;
    }

    case VIDEO_TYPE: {
      const video = document.createElement('iframe');
      video.allow = 'autoplay; fullscreen';
      video.allowFullscreen = true;
      video.className = style.video;
      video.src = message.content;
      content.appendChild(video);
      return content;
    }

    case CARD_TYPE: {
      const { title, subtitle, buttons } = message.content;
      const links = buttons
        .map(b => `<li><a target="_blank" rel="noopener" href="${b.value}">${b.title}</a></li>`)
        .join('');
      content.innerHTML = `<h2>${title}</h2><h3>${subtitle}</h3><ul>${links}</ul>`
      return content;
    }

    default:
      throw new Error(`Unsupported message type ${message.type}`);
  }
}

function addMessage(message:Message) {
  try {
    const content = getContent(message);
    const item = document.createElement('li');
    item.className = message.isBot ? style.bot : style.mine;
    item.appendChild(content);
    messages.appendChild(item);
    scrollToBottom();
    messages.querySelectorAll('img:not([data-tracked])').forEach(img => {
      img.setAttribute('data-tracked', 'true');
      img.removeEventListener('load', scrollToBottom);
      img.addEventListener('load', scrollToBottom);
    });
  } catch (error) {
    console.error(error);
    console.log(message);
  }
}

export function addMessages(messages:Message[]) {
  messages.forEach(addMessage);
}

if (!isFullMode) {
  chat.className = style.closedChat;

  const open = document.createElement('button');
  open.type = 'button';
  open.className = style.open;
  document.currentScript.parentElement.insertBefore(open, document.currentScript);

  open.addEventListener('click', () => {
    chat.className = style.openChat;
    input.focus();
  });

  const close = document.createElement('button');
  close.type = 'button';
  close.className = style.close;
  header.appendChild(close);

  close.addEventListener('click', () => {
    chat.className = style.closedChat;
  });
} else {
  document.body.parentElement.className = style.bg;
}

export function hideCopyright() {
  copyright.style.display = 'none';
}
