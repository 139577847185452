const CHANNEL = document.currentScript.dataset.channel;
const TOKEN = document.currentScript.dataset.token;
// const TIME_OUT = 8000;

/*export class TimeOutError extends Error {
  public readonly isTimeOut = true;

  constructor() {
    super('Request timed out.');
  }
}*/

function request(method:string, path:string, params?:{[k:string]:any}, body?:any) {
  if (params) {
    const sanitized = Object.entries(params).filter(p => p[1] !== undefined);
    if (sanitized.length) {
      const query = new URLSearchParams();
      sanitized.forEach(([n, v]) => query.set(n, v));
      path = `${path}?${query}`;
    }
  }

  const url = `https://api.cai.tools.sap/connect/v1/webhook/${CHANNEL}${path}`;

  const headers = new Headers();
  headers.set('Authorization', TOKEN);

  if (body) {
    headers.set('Content-Type', 'application/json');
  }

  return new Request(url, {
    method,
    headers,
    mode: 'cors',
    cache: 'default',
    body: body && JSON.stringify(body),
  });
}

/*export async function fetchWithTimeOut(request:Request, timeOut = TIME_OUT) {
  return new Promise<Response>((resolve, reject) => {
    let didTimeOut = false;
    const toid = setTimeout(() => {
      didTimeOut = true;
      reject(new TimeOutError());
    }, timeOut);

    fetch(request)
      .then(response => {
        clearTimeout(toid);
        if(!didTimeOut) {
          resolve(response);
        }
      })
      .catch(error => {
        clearTimeout(toid);
        if (!didTimeOut) {
          reject(error)
        }
      })
  });
}*/

export async function get(path:string, params?:{[k:string]:any}) {
  const response = await fetch(request('GET', path, params));
  return await response.json();
}

export async function post(path:string, body?:any, params?:{[k:string]:any}) {
  const response = await fetch(request('POST', path, params, body));
  return await response.json();
}
