import { TEXT_TYPE, Message as ApiMessage } from '../api/types';
import { State, Message } from './types';

export const init:State = {
  isPolling: true,
  messages: [],
  waitTime: 120,
};

export function parseWelcome(content:string):Message {
  return {
    id: 'welcome',
    type: TEXT_TYPE,
    content,
    isBot: true,
  };
}

export function parseMessage(raw:ApiMessage):Message {
  return {
    id: raw.id,
    type: raw.attachment.type,
    content: raw.attachment.content,
    isBot: raw.participant.isBot,
  };
}

export function getLastMessageId(messages:Message[]) {
  const last = messages[messages.length - 1];
  return last && last.id !== 'welcome' ? last.id : undefined;
}
