import { getPreferences, createConversation, getMessages, sendMessage } from '../api';
import { debounce } from '../utils';
import { parseWelcome, parseMessage, getLastMessageId } from './utils';
import { Message } from './types';

let noReply:number;

const NO_REPLY:Message = {
  id: 'no-reply',
  type: 'text',
  content: 'Todavía no tengo respuesta para esto, vuelve a preguntarme en unos días...',
  isBot: true,
};

export async function start() {
  const [preferences, conversation] = await Promise.all([
    getPreferences(),
    createConversation(),
  ]);

  const welcome = parseWelcome(preferences.welcomeMessage);
  const messages = conversation.messages.map(parseMessage);

  return {
    conversation: conversation.id,
    chat: conversation.chatId,
    messages: [welcome, ...messages],
  };
}

export function poll(conversation:string, messages:Message[], render:(messages:Message[]) => void) {
  let isPolling = false;
  let stopWaiting = () => {};
  let lastMessageId = getLastMessageId(messages);

  async function doPolling() {
    isPolling = true;
    const incoming = await getMessages(conversation, lastMessageId);

    const last = incoming.messages[incoming.messages.length - 1];
    if (last && last.participant.isBot) {
      clearTimeout(noReply);
    }

    const messages = incoming.messages.map(parseMessage);
    lastMessageId = getLastMessageId(messages) || lastMessageId;
    render(messages);
    isPolling = false;
    stopWaiting = debounce(doPolling, 1000 * incoming.waitTime);
  }

  function startPollingRightNow() {
    if (!isPolling) {
      stopWaiting();
      doPolling();
    }
  }

  return startPollingRightNow;
}

export function send(chat:string, content:string, polling:() => void, render:(messages:Message[]) => void) {
  clearTimeout(noReply);
  polling();
  sendMessage(chat, content);
  noReply = setTimeout(render, 8000, [NO_REPLY]);
}
