type F<A, R> = (arg:A) => R;

const pending = new Promise((_) => {});

export function lastPromise<A, R, C>(operation:F<A, Promise<R>>, ctx?:C, onLast?:(last:Promise<R>) => void, last?:Promise<R>) {
  const wrap = async (...args:any[]) => {
    if (last && onLast) {
      onLast(last);
    }

    const current = operation.apply(ctx, args as any);
    const isLast = () => current === last;
    last = current;

    try {
      const result = await current;
      return isLast() ? result : pending;
    } catch (error) {
      if (isLast()) {
        throw error;
      } else {
        return pending;
      }
    }
  };

  return wrap as (a?:A) => Promise<R>;
}
