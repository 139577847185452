export function fullHeight() {
  let raf = 0;

  const fix = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', () => {
    window.cancelAnimationFrame(raf);
    raf = window.requestAnimationFrame(fix);
  });

  fix();
}
