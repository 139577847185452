import { getFromStorage, setInStorage } from '../utils';
import { Preferences, Conversation, Message, MESSAGE_TYPES, TEXT_TYPE } from './types';
import { parseType } from './utils';
import { get, post } from './client';

export async function getPreferences() {
  const response = await get('/preferences');
  return response.results as Preferences;
}

export async function createConversation() {
  const response = await post('/conversations');
  return response.results as Conversation;
}

export async function getConversation() {
  const conversation = getFromStorage<Conversation>('conversation') || await createConversation();
  setInStorage('conversation', conversation);
  return conversation;
}

export async function getMessages(conversation:string, lastMessage?:string) {
  const response = await get(
    `/conversations/${conversation}/poll`,
    { last_message_id: lastMessage },
  );

  const waitTime = response.results.waitTime as number;
  const messages = (response.results.messages as Message[])
    .map(parseType);

  return { messages, waitTime };
}

export async function sendMessage(chatId:string, content:string) {
  await post('/', {
    chatId,
    message: {
      attachment: {
        content,
        type: TEXT_TYPE,
      }
    }
  });
}
