export function setInStorage(key:string, value:any) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getFromStorage<T>(key:string):T|null {
  try {
    return JSON.parse(window.localStorage.getItem(key)!);
  } catch(error) {}

  return null;
}

export function removeFromStorage(key:string) {
  window.localStorage.removeItem(key);
}
