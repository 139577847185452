import { start, poll, send } from './flow';
import { sender, input, button, addMessages, hideCopyright } from './ui';

button.disabled = true;

(async () => {
  const { conversation, chat, messages } = await start();
  const polling = poll(conversation, messages, addMessages);

  sender.addEventListener('submit', event => {
    event.preventDefault();
    hideCopyright();
    input.focus();
    const text = input.value.trim();
    if (text) {
      send(chat, text, polling, addMessages);
      input.value = '';
    }
  });

  button.disabled = false;
  addMessages(messages);
})();
